<template>
    <div class="row">
        <div class="col-md-8">
            <h1 class="mao-h1 mao-h1--mb30">
                {{ __('Winkelwagen') }}
                <span
                    v-if="store.total > 0"
                    id="shoppingCartTitle">
                    ({{ store.total }})
                </span>
            </h1>
        </div>

        <div class="col-md-4">
            <a
                v-if="store.total > 0"
                :href="nextButtonAction">
                <button class="mao-button yellow full-w cart-top-button">
                    {{ __(nextButtonTitle) }}
                </button>
            </a>
            <div
                v-else
                class="cart-top-button"></div>
        </div>
    </div>
    <template v-if="store.count > 0">
        <div
            v-if="eventsNotifs.shoppingCartSummaryAlert"
            class="notification-naw mao-notification ticked info">
            {{ __('Toegevoegd aan je winkelwagen') }}
        </div>

        <div class="cart-table">
            <template v-for="item in store.items">
                <div class="cart-table-item">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row cart-table-item__main">
                                <div class="col-xs-3">
                                    <div class="mao-product-fragment__gfx">
                                        <div
                                            class="mao-product-fragment__gfx-gutter"
                                            id="product-image">
                                            <img
                                                :src="item.shoppingImage"
                                                alt=""
                                                style="top: 0" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-9">
                                    <a
                                        :href="item.href"
                                        class="cart-table-item__name">
                                        {{ item.title }}
                                    </a>
                                    <div class="cart-table-item__sku">
                                        {{ item.articleNr }}
                                        <a
                                            @click="store.removeItem(item)"
                                            href="#"
                                            class="mao-blueLink">
                                            {{ __('Verwijderen') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-9 col-sm-push-3 col-md-push-0 col-md-6">
                            <div class="row">
                                <div class="col-xs-4">
                                    <qty-options
                                        :starting-value="item.amount"
                                        :order-quantity="item.orderQuantity"
                                        @input="(value) => updateAmount(item, value.value, true)" />
                                </div>
                                <div class="col-xs-4">
                                    <div class="mao-price-component mao-price-component--cartItem">
                                        <span class="mao-price-component__main">
                                            <span class="mao-price-component-euro">&euro;</span>
                                            <span id="cart-item-subTotalPostDiscount">
                                                {{ toFixedLocal(item.netPrice * siteSettings.btw) }}
                                            </span>
                                        </span>
                                        <span
                                            v-if="item.discount"
                                            class="mao-price-component__secondary">
                                            -{{ item.discount }}%
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-4">
                                    <div class="mao-price-component mao-price-component--cartItem mao-price-component--cartItemTotal">
                                        <span class="mao-price-component__main">
                                            <span class="mao-price-component-euro">&euro;</span>
                                            <span id="cart-item-totalPostDiscount">
                                                {{ toFixedLocal(item.netPrice * siteSettings.btw * item.amount) }}
                                            </span>
                                        </span>

                                        <span
                                            v-if="item.discount"
                                            class="mao-price-component__secondary"
                                            id="cart-item-totalPreDiscount">
                                            {{ toFixedLocal(item.grossPrice * siteSettings.btw * item.amount) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="cart-table-item__description"
                        v-if="item.car != undefined">
                        {{ item.car.carBrandName }}
                        {{ item.car.modelName }}
                        {{ item.car.bodyType }}
                        {{ item.car.typeName }}
                        ({{ item.car.kw }})
                        {{ item.car.engineType }}
                    </div>
                </div>
            </template>
        </div>
        <gift-voucher v-if="siteSettings.isChannel == 'cpd'"></gift-voucher>
        <div :class="{'mao-section-bordered': siteSettings.isChannel != 'cpd'}">
            <div class="row">
                <cart-summary-c-p-d
                    v-if="siteSettings.isChannel == 'cpd'"
                    :next-button-action="nextButtonAction"
                    :next-button-title="nextButtonTitle" />
                <cart-summary
                    v-else
                    :next-button-action="nextButtonAction"
                    :next-button-title="nextButtonTitle" />
            </div>
        </div>
        <gift-voucher v-if="siteSettings.isChannel != 'cpd'" />
    </template>
    <div
        v-else
        class="table">
        <img
            :src="emptyCart"
            class="resize-img-70-percent"
            alt="" />
        <h2 style="text-align: center">{{ __('Er zit helemaal niks in je winkelwagen') }}.</h2>
        <a
            style="text-align: center"
            :href="carPartsLink"
            class="mao-blueLink center-block">
            {{ __('Bekijk het hele assortiment') }}
            <i class="fa fa-angle-right"></i>
        </a>
    </div>
</template>

<script setup lang="ts">
    import {shoppingCartStore, siteSettings as siteSettingsStore, eventsNotif} from './Store.ts';
    import QtyOptions from './QtyOptions.vue';
    import {Product} from './types/Product.ts';
    import CartSummary from './CartSummary.vue';
    import GiftVoucher from './GiftVoucher.vue';
    import CartSummaryCPD from './CartSummaryCPD.vue';
    const store = shoppingCartStore();
    const eventsNotifs = eventsNotif();
    const siteSettings = siteSettingsStore();
    const props = defineProps({
        nextButtonTitle: String,
        nextButtonAction: String,
        emptyCart: String,
        carPartsLink: String,
    });

    const updateAmount = (product: Product, x: number, setAmount: boolean) => {
        store.addItem(product, x, setAmount);
    };
</script>

<style scoped></style>
