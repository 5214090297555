<script setup>
    import {siteSettings as siteSettingsStore} from './Store.ts';
    const siteSettings = siteSettingsStore();
    const props = defineProps({
        noHeader: {
            required: false,
            type: Boolean,
        },

        slim: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: 'lg',
        },

        centered: {
            type: Boolean,
            default: true,
        },

        scrollable: {
            type: Boolean,
            default: false,
        },
        isBlue: {
            type: Boolean,
            default: false,
        },

        title: String,
    });
</script>

<template>
    <div>
        <div class="fade in modal-backdrop"></div>

        <div
            class="modal fade show mao-modal in"
            :class="{'modal-slim': slim}"
            role="dialog"
            @click.self="$emit('close')">
            <div
                class="modal-dialog"
                :class="[`modal-${size}`, centered ? 'modal-dialog-centered' : '', scrollable ? 'modal-dialog-scrollable' : '']"
                role="document">
                <div
                    class="modal-content col--arrow-profile --arrow-right"
                    :class="{'modal-blue': isBlue}">
                    <div
                        v-if="!noHeader"
                        class="modal-header">
                        <h2 class="modal-title">
                            <slot name="header">{{ title }}</slot>
                        </h2>
                        <button
                            @click="$emit('close')"
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span
                                aria-hidden="true"
                                style="float: right">
                                &times;
                            </span>
                        </button>
                    </div>

                    <div class="modal-body carSearch">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .modal {
        overflow-y: auto;

        &.modal-slim {
            .modal-content {
                padding: 12px 16px;
            }
        }

        &.show {
            display: block;
            opacity: 1;
        }

        .modal-content {
            @media (max-width: 768px) {
                padding: 20px;
            }

            @media (max-width: 425px) {
                padding: 0;
            }
        }
    }

    .modal-backdrop {
        opacity: 0.7;
    }
</style>
