<template>
    <div>
        <slot
            :addProduct="addProduct"
            :updateAmount="updateAmount">
            <button
                @click="addProduct()"
                class="mao-button yellow full-w">
                <i class="fa fa-shopping-cart"></i>
                Bestellen
            </button>
        </slot>
        <modal
            v-if="showModal && modalExist"
            @close="showModal = false">
            <template #header>
                <i class="fa fa-check"></i>
                {{ __('Toegevoegd aan je winkelwagen') }}
            </template>
            <template #default>
                <div class="mao-attToCartItem">
                    <div
                        class="mao-thumbImage mao-thumbImage--60x60 mao-thumbImage--bgWhite"
                        id="cart-add-image">
                        <img
                            :src="product.shoppingImage"
                            :alt="product.title"
                            class="list-image-shopping"
                            style="margin-top: -10px" />
                    </div>

                    <div
                        class="mao-productItemTitle mao-font600"
                        id="cart-add-title">
                        {{ product.title }}
                    </div>

                    <div class="mao-price-component mao-price-component--18px">
                        <span class="mao-price-component__main">
                            <span class="mao-price-component-euro">&euro;</span>
                            <span id="cart-add-price-new">{{ toFixedLocal(product.netPrice * siteSettings.btw) }}&nbsp;</span>
                        </span>
                        <span
                            class="mao-price-component__secondary"
                            id="cart-add-price-old">
                            {{ toFixedLocal(product.grossPrice * siteSettings.btw) }}
                        </span>
                    </div>
                </div>
                <div class="row mao-continueOrProceed">
                    <div class="col-sm-6 col-sm-push-6 mao-continueOrProceed__proceed">
                        <a :href="nextAction">
                            <button class="mao-button black buttonMobile">
                                {{ __('Bekijk je winkelwagen') }}
                                <i class="fa fa-angle-right"></i>
                            </button>
                        </a>
                    </div>

                    <div class="col-sm-6 col-sm-pull-6">
                        <span
                            @click="showModal = false"
                            href="#"
                            class="mao-blueLink"
                            data-dismiss="modal">
                            <i class="fa fa-angle-left"></i>
                            {{ __('Verder winkelen') }}
                        </span>
                    </div>
                </div>

                <hr class="mao-hr" />
            </template>
        </modal>
    </div>
</template>

<script setup lang="ts">
    import type {Product} from './types/Product';
    import {onBeforeMount, PropType, ref} from 'vue';
    import {shoppingCartStore, siteSettings as siteSettingsStore, eventsNotif} from './Store.ts';
    import Modal from './Modal.vue';
    let showModal = ref(false);
    const store = shoppingCartStore();
    const siteSettings = siteSettingsStore();
    const eventsNotifs = eventsNotif();
    const props = defineProps({
        item: {
            type: Object as PropType<Product>,
            required: true,
        },
        modalExist: {
            type: Boolean,
            default: true,
        },
    });

    const product = ref<Product | null>(null);
    const amount = ref(props.item.orderQuantity);
    const nextAction = window.route.shoppingcart.cart;
    function updateAmount(x) {
        amount.value = x.value;
    }
    onBeforeMount(() => {
        product.value = store.filterProductFields(props.item);
    });
    function addProduct() {
        store.addItem(product.value, amount.value);
        showModal.value = true;
        eventsNotifs.shoppingCartSummaryAlert = true;
    }
</script>

<style scoped></style>
